<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reset-all-filters="resetAllFilters"
      @reload="$refs['refUsersTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="refUsersTable"
          class="position-relative"
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          no-provider-filtering
          sticky-header="65vh"
          :busy.sync="isBusy"
          :sort-direction="order"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          small
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(users)="data">
            <div>
              <router-link
                :class="[textLink]"
                :to="`/${routeModule}/edit/${data.item.id}`"
              >{{ data.item.user_name }}</router-link>
            </div>
          </template>
          <template #cell(session_status)="data">
            <feather-icon
              icon="CircleIcon"
              size="13"
              style="border-radius: 50%; margin-bottom: 2px; margin-right: 5px"
              :style="
                data.item.state_advisor == 1
                  ? 'background:green; color: green; border-color: green;'
                  : data.item.state_advisor == 2
                    ? 'background:orange; color: orange; border-color: orange;'
                    : data.item.state_advisor == 3
                      ? 'background:red; color: red; border-color: red;'
                      : 'background:#ccc; color: #ccc; border-color: #ccc;'
              "
            />
            <span>
              {{
                data.item.state_advisor == 1
                  ? "Active"
                  : data.item.state_advisor == 2
                    ? "Busy"
                    : data.item.state_advisor == 3
                      ? "Away"
                      : "Offline"
              }}
            </span>
          </template>
          <template #cell(status)="data">
            <span
              class="badget-wo-color"
              style="text-transform: uppercase"
              :style="
                data.item.status == 1
                  ? 'background-color: rgba(0, 204, 0, 0.2); color: rgba(0, 204, 0, 1);'
                  : 'background-color: rgba(255, 0, 0, 0.2); color: rgba(255, 0, 0, 1);'
              "
            >
              {{ data.item.status == 1 ? "Active" : "Inactive" }}
            </span>
          </template>
          <template #cell(module)="data">
            <div v-if="data.item.valid_rol != 1">
              <div
                v-for="(item, index) in JSON.parse(data.item.module)"
                :key="index"
                style="font-weight: bold"
              >
                {{
                  item.module +
                    (item.sub_modules ? " ( " + item.sub_modules + " )" : "")
                }}
              </div>
            </div>
            <div
              v-else
              style="font-weight: bold"
            >
              <li>All</li>
            </div>
          </template>
          <template #cell(main_module)="data">
            <div
              v-for="(item, index) in JSON.parse(data.item.module)"
              :key="index"
            >
              <p v-if="item.main_module == 1">
                {{ item.module }}
              </p>
            </div>
          </template>
          <template #cell(role)="data">
            <div v-if="data.item.valid_rol != 1">
              <div
                v-for="(item, index) in JSON.parse(data.item.module)"
                :key="index"
              >
                <show-more-roles
                  :seller-level="item.role"
                  :seller-pending="
                    data.item.status_ts_num === 1 && item.id_module === 21
                  "
                  :item="data.item"
                />
              </div>
            </div>
            <div v-else>
              <li>All</li>
            </div>
          </template>
          <template #cell(created_by)="data">
            {{ data.item.creator_name }}
            <br>
            <span style="color: rgba(0, 204, 0, 1)">{{
              data.item.created_at | myGlobalDay
            }}</span>
          </template>
          <template #cell(actions)="data">
            <div
              title="Edit"
              style="display: inline-block"
            >
              <router-link
                :class="[textLink]"
                :to="`/${routeModule}/edit/${data.item.id}`"
                :props="{ editMode: 'editmode' }"
              ><feather-icon
                class="text-warning cursor-pointer"
                size="19"
                icon="EditIcon"
              /></router-link>
            </div>
            <div
              title="Sessions"
              style="display: inline-block"
            >
              <router-link
                :class="[textLink]"
                :to="`/${routeModule}/session/${data.item.id}`"
              ><feather-icon
                class="text-success cursor-pointer"
                size="19"
                icon="UserIcon"
              /></router-link>
            </div>
            <div
              v-if="data.item.cc_clients > 0 && data.item.status == 0"
              title="Reassign this advisor's clients"
              style="display: inline-block"
            >
              <tabler-icon
                class="text-info cursor-pointer"
                size="19"
                icon="SwitchHorizontalIcon"
                @click="openModalAssignClients(data.item)"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-clients-assigned
      v-if="modalClientsAssignedState"
      :data-item="dataItem"
      @hideModal="modalClientsAssignedState = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalClientsAssigned from '@/views/ce-digital/sub-modules/settings/views/users/view/components/ModalClientsAssigned';
import ShowMoreRoles from '@/views/users/commons/users/user-view/ShowMoreRoles.vue';
import dataFields from './fields.data';
import dataFilter from './filters.data';
import UsersService from '../services/users.service';

export default {
  components: {
    ModalClientsAssigned,
    ShowMoreRoles,
  },

  prop: {
    tabData: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      totalRows: 0,
      filters: dataFilter,
      fields: dataFields,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      toPage: null,
      isBusy: false,
      items: [],
      order: 'desc',
      sortBy: 'created_at',
      modalClientsAssignedState: false,
      dataItem: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    visibleFields() {
      this.fields[9].visible = this.moduleId === 1 || this.currentUser.user_id === 1;
      return this.fields.filter(field => field.visible);
    },
    modules() {
      return this.filters[2].model;
    },
    routeModule() {
      return this.$route.meta.route;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    modules(newVal) {
      this.loadNewrols(newVal);
    },
  },
  mounted() {
    this.allModules();
    this.filters[3].options = [
      {
        name: 'All',
        id: 0,
      },
    ];
  },
  methods: {
    resetAllFilters() {
      this.filters.forEach(filter => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.refUsersTable.refresh();
    },
    openModalAssignClients(data) {
      this.dataItem = {
        user_id: data.id,
        user_name: data.user_name,
      };
      this.modalClientsAssignedState = true;
    },
    async allModules() {
      const data = await UsersService.allModules();
      const newData = data;
      newData.unshift({
        name: 'All',
        id: 0,
      });
      this.filters[2].options = newData;
      this.filters[4].options = newData;
    },
    async loadNewrols(module) {
      const data = await UsersService.loadNewRoles({ id: module });
      const newData = data;
      newData.unshift({
        name: 'All',
        id: 0,
      });
      this.filters[3].options = newData;
    },
    async myProvider(ctx) {
      try {
        let sortBy = 11;
        let sortDirection = this.order;
        if (ctx.sortBy === 'created_by') {
          sortBy = 11;
          if (ctx.sortDesc) sortDirection = 'desc';
          else sortDirection = 'asc';
        }
        const params = {
          name_text: this.filterPrincipal.model,
          status: this.$route.meta.isActive,
          module: this.filters[2].model,
          rolenew: this.filters[3].model,
          orderby: sortBy,
          order: sortDirection,
          date_from: this.filters[0].model,
          date_to: this.filters[1].model,
          user_id: this.currentUser.user_id,
          main_module: this.filters[4].model,
          perpage: ctx.perPage,
        };
        const data = await UsersService.searchUsers(params, ctx.currentPage);
        this.items = data.data.data;

        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;

        return this.items || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>

<style>
.badget-wo-color {
  padding: 3px 6px;
  border-radius: 5px;
  font-weight: bold;
}
</style>
