<template>
  <div>
    <div>
      {{ SellerLevel.length > 14
        ? changeText
            ? `${SellerLevel.replace(/\n/g).substr(0, 7)} ...`
            : SellerLevel.replace(/\n/g)
        : SellerLevel.replace(/\n/g) }}
      <feather-icon
          v-if="sellerPending"
          v-b-tooltip.hover.top="'Seller level request : ' + item.typesenior_pending + ' is pending. '"
          icon="InfoIcon"
          class="cursor-pointer text-danger"
      />
    </div>
    <b-button
      v-if="SellerLevel.length > 14"
      variant="flat-info"
      size="sm"
      class="text-nowrap"
      @click="returnDataLevel()"
    >{{ formatedBody }}</b-button>
  </div>
</template>

<script>
export default {
  props: {
    SellerLevel: {
      type: String,
    },
    sellerPending: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      changeText: true,
    }
  },
  computed: {
    formatedBody() {
      return this.changeText == true ? '...' : 'Hide'
    },
  },
  methods: {
    returnDataLevel() {
      this.changeText = !this.changeText
    },
  },
}
</script>
