import { amgApi } from "@/service/axios"

class UsersService {
    async searchUsers(params, page) {
        try {
            const data = await amgApi.post(`/commons/user-search?page=${page}`, params)
            return data
        } catch (error) {
            console.log("Something went wrong on searchUsers ", error);
            throw error
        }
    }
    async allModules() {
        try {
            const { data } = await amgApi.get(`/module/get-modules`)
            return data
        } catch (error) {
            console.log("Something went wrong on allModules ", error);
            throw error
        }
    }
    async loadNewRoles(param) {
        try {
            const { data } = await amgApi.post(`/roles/roles-news`,param)
            return data
        } catch (error) {
            console.log("Something went wrong on loadNewRoles ", error);
            throw error
        }
    }

    async searchSessionsUsers(params, page) {
        try {
            const data = await amgApi.post(`/commons/session-users?page=${page}`, params)
            return data
        } catch (error) {
            console.log("Something went wrong on searchSessionsUsers ", error);
            throw error
        }
    }
}
export default new UsersService();