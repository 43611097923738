export default [
    {
      key: 'users',
      sortable: true,
      label: 'Users',
      visible: true
    },
    {
      key: 'session_status',
      label: 'Session Status',
      visible: true
    },
    {
      key: 'email',
      sortable: true,
      label: 'Email',
      visible: true
    },
    {
      key: 'status',
      label: 'Status',
      visible: true
    },
    {
      key: 'module',
      label: 'Module',
      visible: true
    },
    {
      key: 'main_module',
      label: 'Main Module',
      visible: true
    },
    {
      key: 'role',
      label: 'Role',
      visible: true
    },
    {
      key: 'phone',
      label: 'Phone Number',
      visible: true
    },
    {
        key: 'created_by',
        sortable: true,
        label: 'Created By',
        visible: true
    },
    {
        key: 'actions',
        label: 'Actions',
        visible: true
    },
]